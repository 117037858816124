import { ExtendedBenefits, RichtextItem, SubscriptionPlan, T_FormField } from "@/types/types";
import { bannerAdvantagesProps, bannerProps, billboardProps, detailedProps, faqs, faqsHomeTabProps, featuredAIProps, featuredProps, previewHome } from "./home";
import { FormFieldProps } from "@/components/Form/types";
import { BannerProps, PreviewProps, TabsProps, ReasonsProps, BenefitsProps } from "./landingsSEM";

export enum PAGES {
  HOME = "home",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
  TERMS_OF_USE = "terms-of-use",
  PRIVACY_POLICY = "privacy-policy",
  COOKIES_POLICY = "cookies-policy",
  CONTACT_US = "contact-us",
  FAQ = "faq",
  PRICING = "pricing",
  WHOWEARE = "who-we-are",
  QR_EDITOR = "qr-editor",
  NOT_FOUND = "not-found",
  SIDEBAR = "sidebar",
  LOGIN = "login",
  REGISTER = "register",
  AUTH = "auth",
  RECOVER_PASSWORD = "recover-password",
  INACTIVE = "inactive",
  THANK_YOU = "thank-you",
  PAYMENT = "payment",
  VERIFY_PAYMENT = "verify-payment",
  MY_QR_CODE_DETAILS = "my-qr-code-details",
  MY_QR_ANALYTICS = "my-qr-analytics",
  MY_ACCOUNT = "my-account",
  MY_QR_CODES = "my-qr-codes",
  QR_VIEW = "qr",
  MY_BILLING = "my-billing",
  UNSUBSCRIBE = "unsubscribe",
  UNSUBSCRIBE_REASONS = "unsubscribe-reasons",
  UNSUBSCRIBE_CONFIRMATION = "unsubscribe-confirmation",
  NEW_PASSWORD = "reset",
  QR_URL = "url",
  QR_VCARD = "vcard",
  QR_BUSINESS = "business",
  QR_SOCIAL = "social",
  QR_APPS = "apps",
  QR_VIDEO = "video",
  QR_PDF = "pdf",
  QR_LISTOFLINKS = "listoflinks",
  QR_WIFI = "wifi",
  QR_IMAGE = "image",
  QR_YOUTUBE = "youtube",
  QR_WEBSITE = "website",
  QR_EVENT = "event",
  QR_DESIGN = "qr-design",
  QR_REGISTER = "qr-register",
  QR_AI_DESIGN = "qr-design-ai",
  HOME_B = "qr-code-generator",
  GENERIC_ERRORS = "generic-errors",
  PDF_LANDING = "pdf-qr-code",
  VCARD_LANDING = "vcard-qr-code",
}

export const previewTranslationNamespaces = [
  PAGES.QR_BUSINESS,
  PAGES.QR_EVENT,
  PAGES.QR_VCARD,
];

export const QR_EDITOR_LAYOUT = "qr-editor-layout";

export interface IStaticPage {
  page: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords?: string;
  title?: string;
}
export interface IDashboardPage {
  page: string;
  title: string;
}

export interface IUnsubscribePage extends IDashboardPage{
  backButtonLabel: string;
}

export interface I_StaticLegalPage extends IStaticPage {
  subtitle: string;
  richtext: RichtextItem[];
}

export interface I_InactivePage extends IStaticPage {
  image: string;
  richtext: RichtextItem[];
}
export interface I_PaymentPage extends IStaticPage {
  iframeTitle: string;
  iframeTerms: string;
  iframeTermsAB: string;
  iframeRenew: string;
  iframeSubmitButton: string;
  iframeSubmitButtonAB: string;
  summarySelectionTitle: string;
  summaryInfo: string;
  summaryInfoTrial: string;
  summaryLegal: string;
  summaryOrderTitle: string;
  summaryOrderTotal: string;
  summaryContactTitle: string;
  summaryContactInfoHighlighted: string;
  summaryContactInfoText: string;
  summaryContactInfoPhone: string;
  summaryCertTitle: string;
  summaryCertInfo: string;
  textBeforeTimer: string;
  textAfterTimer: string;
  errorMessageTitle: string;
  errorMessageDescription: string;
}

export interface I_ThankYouPage {
  page: string;
  title: string;
  bannerTitle: string;
  bannerAction: {
    title: string;
    button: string;
    aiButton: string;
  };
}

export interface I_VerifyPaymentPage {
  page: string;
  loadingTitle: string;
  loadingSubtitle: string;
}

export interface I_PrincingPage extends IStaticPage {
  subtitle_1: string;
  subtitle_2: string;
  prices: SubscriptionPlan[];
  phone: {
    richtext: RichtextItem[];
  };
  banner?: {
    title: string;
    list: string[];
  }
  version?: string
  containerComponent?: string
  benefitsExtended?:{
    title?: string
    listExtended: ExtendedBenefits[]
  };
}

export interface I_HomePage extends IStaticPage {
  billboard: billboardProps;
  featured: featuredProps;
  featured2: featuredProps;
  featuredAI: featuredAIProps;
  banner: bannerAdvantagesProps;
  banner2: bannerProps;
  detailed: detailedProps;
  faqsHomeTabs: faqsHomeTabProps[];
  faqsHome: faqs;
  previewHome: previewHome;
}

export interface IResetPasswordPage extends IStaticPage {
  formFields: T_FormField[];
}
export interface I_QREditorFormTemplate {
  page: string;
  metaTitle: string;
  metaDescription: string;
  title: string;
  formFields: FormFieldProps[];
  layoutTranslations?: any;
}


export interface I_LandingPage extends IStaticPage {
  preview: PreviewProps,
  banner: BannerProps,
  landingTabs: TabsProps
  landingBenefits:BenefitsProps
  landingReasons: ReasonsProps
}

